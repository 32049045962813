import React from "react";
//import Footer from './../components/footer';
//import Banner from './../components/banner';
import ContactUs from "./../components/home/contact-us";
import Vissions from "./../components/home/visions";
import Portofolios from "./../components/home/portofolios";
import Navbar from "../components/navbar";
import Footer from "../components/home/footer";

class Home extends React.Component {
  render() {
    return (
      <div className="main">
        <Navbar></Navbar>
        <Vissions></Vissions>
        <Portofolios></Portofolios>
        <ContactUs></ContactUs>
        <Footer></Footer>
      </div>
    );
  }
}

export default Home;
