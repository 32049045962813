import React from "react";

import Image1 from "./../../assets/img/portfolio//portfolio-1.jpg";
import Image2 from "./../../assets/img/portfolio//portfolio-2.jpg";
import Image3 from "./../../assets/img/portfolio//portfolio-3.jpg";
import Image4 from "./../../assets/img/portfolio//portfolio-4.jpg";
import Image5 from "./../../assets/img/portfolio//portfolio-5.jpg";
import Image6 from "./../../assets/img/portfolio//portfolio-6.jpg";

class Portofolios extends React.Component {
  constructor(props) {
    super(props);
    this.getDataPortofilios = this.getDataPortofilios.bind(this);

    this.state = {
      isLoading: null,
        portfolios: [{image: Image1}, {image: Image2},{image: Image3},{image: Image4},{image: Image5},{image: Image6}]
    };
  }

  async getDataPortofilios() {

  }

  render() {
    return (
      <div id="portfolio" className="portfolio">
        <section id="portfolio">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Portfolio</h2>
              <p>Check out our beautifull portfolio</p>
            </div>

            <div
              class="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >

            {this.state.portfolios.map((item, index) => (
                <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                <div class="portfolio-wrap">
                  <img src={item.image} class="img-fluid" alt="" />
                  <div class="portfolio-links">
                    <a
                      href={item.image}
                      data-gallery="portfolioGallery"
                      class="portfolio-lightbox"
                      title="App 1"
                    >
                      <i class="bi bi-plus"></i>
                    </a>
                    <a href="portfolio-details.html" title="More Details">
                      <i class="bi bi-link"></i>
                    </a>
                  </div>
                  <div class="portfolio-info">
                    <h4>App 1</h4>
                    <p>App</p>
                  </div>
                </div>
              </div>
            ))}
             
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Portofolios;
