import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from 'react';
import Home from './page/home';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <Home></Home>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;